<template>
  <div class="main">
    <div class="box">
      <h1>{{ $t("privacy.title")}}</h1>
      <p v-html="$t('privacy.text1')"></p>
      <p v-html="$t('privacy.text2')"></p>
      <p v-html="$t('privacy.text3')"></p>
      <p v-html="$t('privacy.text4')"></p>
      <p v-html="$t('privacy.text5')"></p>
      <p v-html="$t('privacy.text6', {mail : 'dpo@safeorthopaedics.com'})"></p>
    </div>
  </div>
</template>

<script>

export default {
  name: "DataPrivacy",
}
</script>

<style scoped lang="scss">
  h1 {
    color:#fff;
    text-align: center;
    text-transform: uppercase;
    font-size:35px;
    font-weight:400;
    margin-bottom:80px;
    margin-top:80px;
  }
</style>
